import React from "react"
import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext } from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';

import PhoneWall from "../../content/pages/home/wall-final.svg";
import Control from "../../content/pages/home/backgrounds/control.svg";
import ShuttingDown from "../../content/pages/home/backgrounds/shutting-down.svg";
import WordWithYou from "../../content/pages/home/backgrounds/word-with-you.svg";
import Learn from "../../content/pages/about/learn-reflect.svg";

const ResourcesPage = ({ children }) => (
    <CarouselProvider
        naturalSlideWidth={100}
        naturalSlideHeight={125}
        totalSlides={3}
      >
        <Slider>
            <Slide index={0}>
                <div className="svg-hero">
                    <PhoneWall/>
                </div> 
            </Slide>
            <Slide index={0}>
                <div className="svg-hero">
                    <Control/>
                </div> 
            </Slide>
            <Slide index={0}>
                <div className="svg-hero">
                    <ShuttingDown/>
                </div> 
            </Slide>
            <Slide index={0}>
                <div className="svg-hero">
                    <Learn/>
                </div> 
            </Slide>
        </Slider>
      </CarouselProvider>
  )  

export default ResourcesPage
